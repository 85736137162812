;

'use strict';

const contactForm = document.getElementById('frm-contact');

/**
 * Initialize menú for responsive
 */
const initMenu = () => {
  const menu = document.querySelector('#main-menu');
  const btnTogglemenu = document.querySelector('#btn-toggle-menu');

  btnTogglemenu.addEventListener('click', (event) => {
    event.preventDefault();
    menu.classList.toggle('open');
  });
}

const initCarousel = () => {
  $('#carousel-testimonials').owlCarousel({
    loop: true,
    margin: 30,
    nav: false,
    dots: true,
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 2
      },
      1000: {
        items: 2
      }
    }
  });
};

const sendMessage = (e) => {
  e.preventDefault();
  const data = new FormData(contactForm);

  fetch('./email.php', {
    method: 'POST',
    body: data
  })
    .then(response => {
      console.log(response);
    })
    .catch(err => {
      console.log(err);
    })
    .finally(() => { 
      alert('eseee'); 
    });
}

const init = () => {
  initMenu();
  initCarousel();

  contactForm.addEventListener('submit', sendMessage);
};

document.addEventListener('DOMContentLoaded', init);